/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).foundation(); // Foundation JavaScript
        
        $('.slick-slider').slick({
          dots: false,
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
          responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true
              }
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true   
              }
            }]          
        });

        $('.slick-slider-partenaires').slick({
          dots: false,
          slidesToShow: 6,
          arrows: false,
          infinite: false,
          slidesToScroll: 1,
          responsive: [{
              breakpoint: 1024,
              settings: {
                dots: true,
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }, {
              breakpoint: 480,
              settings: {
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }]          
        });

        var countNbPopin = $('#popin .popincontent').length-1;

        $('.op-popin').on('click', function(event){
          event.preventDefault();
          var id = $(this).attr('data-id');
          var popin = $('#popin');
          $('#popin .content-popin .popincontent').removeClass('active').css('display', 'none');
          popin.find('.pop'+id).addClass('active').show();
          popin.foundation('open');     
          arrowDetect()
        });

        $('.arrow').on('click', function(event){
          event.preventDefault();
          var activePopin = $('.popincontent.active');
          var prevPopin = activePopin.prev();
          var nextPopin = activePopin.next();
          if($(this).hasClass('prev')){
            activePopin.removeClass('active').hide();
            prevPopin.addClass('active').show(); 
          }else if($(this).hasClass('next')){
            activePopin.removeClass('active').hide();
            nextPopin.addClass('active').show();  
          }
          arrowDetect();
        });

        var arrowDetect = function(){
          var activePopin = $('.popincontent.active');
          var activeIndex = activePopin.index();
          if(activeIndex == 0){
            $('.arrow.prev').hide();
          }else{
            $('.arrow.prev').show();
          }
          if(activeIndex == countNbPopin){
            $('.arrow.next').hide();
          }else{
            $('.arrow.next').show();
          }          
        }

        if ($( window ).width() >= 1024) {

        // var controller = new ScrollMagic.Controller();     
        // var heightList = $(window).height()-$('.all-logos').outerHeight();
        // var tween = new TimelineMax()
        //     .to(".section2 .list-images", 10, {'top': "-"+heightList+'px', rotation: 0.01});          
        // new ScrollMagic.Scene({triggerElement: ".section2", triggerHook: "onLeave", duration: $('.all-logos').outerHeight()})
        //         .setPin(".section2")
        //         .setTween(tween)
        //         .addTo(controller); 

        var run = false;
        var run2 = false;
        var tl = new TimelineMax(); 

        $(window).on('resize scroll', function() {
          if($(window).scrollTop() >= $('.section3').offset().top-($(window).height()/2)){
            if(run === false){
              $('.number').each(function(index){
                var number = $(this).attr('data-anim');
                var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(' ')
                $(this).animateNumber({ number: number, easing: 'easeInQuad', numberStep: comma_separator_number_step}, 2000);
              });
              run = true;
            }
          }

          // if($(window).scrollTop() >= $('.section4').offset().top-($(window).height()/2)){
          //   if(run2 === false){
          //       tl.set($('.ani'), {scale:1.1, transformOrigin:"50% 50%"})
          //       .to($('.ani'), 3, {scale: 1, transformOrigin:"50% 50%", ease:Elastic.easeOut.config(1.5,0.2), yoyo:true});
          //       run2 = true;
          //   }
          // }                   

        });

        }else{
          $('.number').each(function(index){
            var number = $(this).attr('data-anim');
            var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(' ')
            $(this).animateNumber({ number: number, easing: 'easeInQuad', numberStep: comma_separator_number_step}, 1);
          });          
        }
              

        /* ====== Add Smooth effect ===== */
        var scrollToAnchor = function( id ) {
          var elem = $("section[id='"+ id +"']"); // on crée une balise d'ancrage
          var headerH = $('header').height();
          // console.log(headerH);
          if ( typeof elem.offset()  === "undefined" ) { // on verifie si l'élément existe
          elem = $("#"+id); }
          if ( typeof elem.offset()  !== "undefined" ) { // si l'élément existe, on continue
            $('html, body').animate({
                    scrollTop: elem.offset().top-headerH }, 600 );} // on défini un temps de défilement de page
        };

        $(".top-bar .menu a, .scroll-to").click(function( event ) { // on attache la fonction au click
          if ( $(this).attr("href").match("#") ) { // on vérifie qu'il s'agit d'une ancre
            event.preventDefault();
            var href = $(this).attr('href').replace('#', '') // on scroll vers la cible

            if($(window).width() < 1024){
              $('.top-bar').hide();
            }
            
            scrollToAnchor( href ); 
          }
        });
        
        /* ====== add class on pagination if the section is visible ====== */
        $(document).scroll(function() {
          var cutoff = $(window).scrollTop() + 200; // on défini la position de déclenchement (*1)
          // Find current section and highlight nav menu
          var curSec = $.find('.current'); // on cherche l'élément (section) avec la class current
          var curID = $(curSec).attr('id'); // on récupère son ID
          var curNav = $.find('a[href=#'+curID+']'); // on cherche l'élément de navigation correspondant (*2)
          $('.top-bar .menu li').removeClass('active'); // on nettoie la navigation de la class active présente
          $(curNav).parent().addClass('active'); // (*2) -> on ajoute la class active
          $('.section').each(function(){
            if($(this).offset().top + $(this).height() > cutoff){ // si la section est dans le champ de scroll
            $('.section').removeClass('current') // on nettoie les sections de la class current présente
            $(this).addClass('current'); // on ajoute la class current à la section visible
            return false; // on stoppe l’itération (le cas contraire, seule la derniere section se verra ajouter la class)
          }
          });
        });

        $('.close-social, .button.share').on("click", function(event){
          event.preventDefault();
          $('.social-share').toggleClass('active');
        });        

        $(document).on("click", ".share-link", function (event) {
            event.preventDefault();
            var url = $(this).attr('data-url');
            window.open( url, "myWindow", "status = 1, height = 500, width = 600, resizable = 0" );
        });

        $('[data-tabs]').on('change.zf.tabs', function() {
          scrollToAnchor( "evolution" ); 
        });        

        $('.scroll-top').on('click', function(event) {
          event.preventDefault();
           $('html, body').animate({scrollTop: 0}, 800 ); // on défini un temps de défilement de page
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
